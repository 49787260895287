import { render, staticRenderFns } from "./ModalRecoveryPassword.vue?vue&type=template&id=bc45c3ca&scoped=true&"
import script from "./ModalRecoveryPassword.vue?vue&type=script&lang=js&"
export * from "./ModalRecoveryPassword.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc45c3ca",
  null
  
)

export default component.exports