<template>
  <b-tabs class="page-tabs" v-model="tabIndex" pills>
    <b-tab>
      <template #title>
        <span>
          Anagrafica
        </span>
      </template>
      <div class="tab-pane__content">
        <div v-if="!isCompanyProfile">
          <form-user-data :read-only="personReadOnlyMode" v-if="isLoadedData"/>
        </div>
        <div v-if="isCompanyProfile">
          <form-company-data :read-only="companyReadOnlyMode" v-if="!isLoadingCompany && isLoadedCompanyData"/>
          <div class="mt-3">
            <form-company-legal-data :read-only="companyReadOnlyMode" v-if="!isLoadingCompany && isLoadedCompanyData"/>
          </div>
        </div>
        <validation-action
          :submit-validation="submitValidation"
          :is-not-complete-info="isNotCompleteInfo"
          :notValid="!userValidate"
        />
        <b-card
          v-if="!isCompanyProfile && userValidate"
          body-class="p-2 text-right"
          class="bg-gray-100 mt-3 border-0"
        >
          <b-button
            size="sm"
            @click="openPwRecovery"
          >
            Modifica password
          </b-button>
        </b-card>
        <modal-recovery-password
          id="pwRecovery"
          :email="entityEmail"
        />
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Documentazione
        </span>
      </template>
      <div class="tab-pane__content">
        <h5 class="py-5 text-center" v-if="!isActiveUser">
          <strong>
            Fornisci la documentazione che ti permette di ottenere il tuo scoring CRP
          </strong>
        </h5>
        <user-documents-list
          entity-class="PERSON"
          :entity-id="entityId"
          :is-not-complete-info="isNotCompleteInfo"
          v-if="isLoadedData && !isCompanyProfile"
          :read-only="personReadOnlyMode"
        />
        <company-documents-list
          entity-class="COMPANY"
          :entity-id="entityId"
          :is-not-complete-info="isNotCompleteInfo"
          v-if="isLoadedData && isCompanyProfile"
        />
        <validation-action
          :submit-validation="submitValidation"
          :is-not-complete-info="isNotCompleteInfo"
          :notValid="!userValidate"
        />
      </div>
    </b-tab>
    <b-tab v-if="isActiveUser && !isCompanyProfile" :active="isRelations">
      <template #title>
        <span>Le tue Aziende</span>
      </template>
      <div class="tab-pane__content">
        <user-companies />
      </div>
    </b-tab>
    <b-tab v-if="isActiveUser && isCompanyProfile" :active="isRelations">
      <template #title>
        <span>Utenti collegati</span>
      </template>
      <div class="tab-pane__content">
        <company-relations />
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '../../utils/validators';
import ModalRecoveryPassword from './ModalRecoveryPassword.vue';

const ValidationAction = () => import('@/components/helpers/ValidationAction.vue');
const UserCompanies = () => import('@/components/userSections/UserCompanies.vue');
const FormUserData = () => import('@/components/forms/FormUserData.vue');
const FormCompanyData = () => import('@/components/forms/FormCompanyData.vue');
const UserDocumentsList = () => import('@/components/userSections/UserDocumentsList.vue');
const CompanyDocumentsList = () => import('@/components/userSections/CompanyDocumentsList.vue');
const FormCompanyLegalData = () => import('@/components/forms/FormCompanyLegalData.vue');
const CompanyRelations = () => import('@/components/userSections/CompanyRelations.vue');

export default {
  name: 'TabData',
  components: {
    ModalRecoveryPassword,
    CompanyRelations,
    ValidationAction,
    UserCompanies,
    FormUserData,
    FormCompanyData,
    UserDocumentsList,
    CompanyDocumentsList,
    FormCompanyLegalData,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  mounted() {
    if (this.hasActiveTab) this.tabIndex = this.defaultTab;
  },
  computed: {
    hasActiveTab() {
      // console.log('check tabs', this.$route.params.activeTab);
      return this.$route.params.activeTab;
    },
    defaultTab() {
      if (this.hasActiveTab) {
        if (this.$route.params.activeTab === 'companies') return 2;
      }
      return '';
    },
    isRelations() {
      return this.defaultTab === 2;
    },
    isLoadedData() {
      return this.$store.getters['subject/isLoadedData'];
    },
    isActiveUser() {
      return this.$store.getters['subject/isActiveUser'];
    },
    isWaitUser() {
      const status = this.$store.getters['subject/activityPersonStatus'];
      return status.waitApproval;
    },
    isNotCompleteInfo() {
      if (this.isCompanyProfile) {
        // console.log('validation company?', this.$store.getters['subject/canSendCompanyValidation']);
        return !this.$store.getters['subject/canSendCompanyValidation'];
      }
      // console.log('validation person?', this.$store.getters['subject/canSendPersonValidation']);
      return !this.$store.getters['subject/canSendPersonValidation'];
    },
    userValidate() {
      return !this.notValid && this.extendedStatus.userStatus === 'valid';
    },
    serialCodeCreation() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCodeCreation;
      }
      return '';
    },
    isLoading() {
      if (!this.isCompanyProfile) {
        this.changePreloaderStatus(this.$store.getters['subject/isLoading'] || this.$store.getters['score/isLoading']);
      }
      return this.$store.getters['subject/isLoading'] || this.$store.getters['score/isLoading'];
    },
    personReadOnlyMode() {
      return !this.$store.getters['subject/canEditPerson'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    // totalPoint() {
    //   if (this.hasScoreData) {
    //     const score = this.$store.getters['score/getCurrentScore'];
    //     return score.totalPoint;
    //   }
    //   return 0;
    // },
    // countTotal() {
    //   if (this.hasScoreData) {
    //     const score = this.$store.getters['score/getCurrentScore'];
    //     return score.globalVotes?.countTotal;
    //   }
    //   return 0;
    // },
    // countTotalMax() {
    //   let increm = this.countTotal * 0.2;
    //   if (increm < 1) {
    //     increm = 2;
    //   }
    //   return this.countTotal + increm;
    // },
    // countFeedbackMadeTotal() {
    //   if (this.hasScoreData) {
    //     const score = this.$store.getters['score/getCurrentScore'];
    //     return score.feedbackMade;
    //   }
    //   return 0;
    // },
    // countFeedbackMadeTotalMax() {
    //   let increm = this.countFeedbackMadeTotal * 0.2;
    //   if (increm < 1) {
    //     increm = 2;
    //   }
    //   return this.countFeedbackMadeTotal + increm;
    // },
    serialCode() {
      if (this.hasScoreData) {
        const score = this.$store.getters['score/getCurrentScore'];
        return score.serialCode;
      }
      return '';
    },
    companyReadOnlyMode() {
      return !this.$store.getters['subject/canEditCompany'];
    },
    isLoadingCompany() {
      if (this.isCompanyProfile) {
        this.changePreloaderStatus(this.$store.getters['subject/isLoadingCompany']);
      }
      return this.$store.getters['subject/isLoadingCompany'];
    },
    isLoadedCompanyData() {
      return this.$store.getters['subject/isLoadedCompanyData'];
    },
    entityId() {
      if (this.isLoadedData) {
        if (this.isCompanyProfile) {
          return this.$store.getters['subject/currentCompany'].companyId;
        }
        return this.$store.getters['subject/currentPerson'].personId;
      }
      return '';
    },
    entityEmail() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].attribute.mainPecEmail.contactData;
      }
      return this.$store.getters['subject/currentPerson'].personInfo.email;
    },
    extendedStatus() {
      return this.$store.getters['subject/currentExtendedStatus'];
    },
  },
  methods: {
    setDashboardState() {
      this.$store.dispatch('setDashboard', 0);
    },
    submitValidation() {
      this.notValid = false;
      this.$confirm(
        {
          message: "Vuoi richiedere la validazione dell'anagrafica?",
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              const entityId = this.isCompanyProfile ? this.entityId : null;
              this.$store.dispatch('subject/sendValidationRequest', entityId).then(() => {
                this.submitted = false;
              },
              (error) => {
                if (error.status === 412) {
                  this.notValid = true;
                } else {
                  this.message = extractErrorMessage(error);
                }
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    changePreloaderStatus(state) {
      // console.log('state', state);
      this.hasLoaded = state;
      console.debug('TabData setpreloader', state);
      this.$store.dispatch('setPreloader', state);
    },
    openModalUpload(doc, documentName) {
      this.documentToUpload = {
        document: doc,
        documentName,
      };
      this.$bvModal.show('userDocs');
    },
    onUploadDoc() {
      this.openModalUpload(this.currentIdentityDoc, 'Documento d\'identità');
    },
    openPwRecovery() {
      this.$bvModal.show('pwRecovery');
    },
  },
};
</script>

<style scoped>

</style>
