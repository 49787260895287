<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Modifica password
    </template>
    <template #body>
      <div v-if="!hasError">
        <div v-if="!isLoading">
          <p class="mb-3">
            Clicca su conferma per procedere alla modifica della password per:
            <br />
            <strong>{{emailData}}</strong>
          </p>
          <b-button
            @click="submitRecovery"
            variant="success"
            block
          >
            CONFERMA
          </b-button>
        </div>
        <div v-else>
          <loader-info />
        </div>
      </div>
      <div v-else>
        <p>
          Si è verificato un errore, riprova più tardi.
        </p>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { extractErrorMessage } from '../../utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
export default {
  name: 'ModalRecoveryPassword',
  components: { ModalWrapper, LoaderInfo },
  props: {
    id: String,
    email: String,
  },
  data() {
    return {
      successful: false,
      submitted: false,
      emailData: this.email || '',
      hasError: false,
      isLoading: false,
    };
  },
  methods: {
    submitRecovery() {
      console.log('submitRecovery');
      this.submitted = true;
      this.isLoading = true;
      this.$store.dispatch('auth/forgotPassword', {
        email: this.emailData,
        noLogout: true,
      }).then((response) => {
        this.message = response.message;
        // this.$router.replace('/');
        this.successful = true;
        this.submitted = false;
        this.isLoading = false;
        this.closeModal();
      },
      (error) => {
        this.message = extractErrorMessage(error);
        this.successful = false;
        this.submitted = false;
        this.isLoading = false;
      });
    },
    closeModal() {
      this.$bvModal.hide('pwRecovery');
    },
  },
};
</script>

<style scoped>

</style>
